<template>
  <div class="app-container">
    <div class="page-title" style="display:flex; flex-direction:row">
      <h2>{{ $t('campagneagricole.campagneagricoles') }}</h2>
      <h2>🏁</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>🏁</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Campagne Agricole
            </div>
            <count-to :start-val="0" :end-val="list.length" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubCampagneagricolesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubCampagneagricolesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterCampagneagricoles">{{ $t('general.disponibles') }}</el-radio>
        <!-- <el-radio v-model="activeList" label="Clôturés" border @change="getSubCampagneagricolesList('indexdesactive')">{{ $t('general.clotures') }}</el-radio> -->
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubCampagneagricolesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterCampagneagricoles" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterCampagneagricoles">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer campagneagricole'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateCampagneagricole">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')" min-width="230px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!=='corbeille' && checkPermission(['activer campagneagricole'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              :disabled="scope.row.id !== list[0].id"
              @change="setCampagneagricoleStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!=='corbeille' && checkPermission(['activer campagneagricole'])" align="center" :label="$t('general.labelColonneCloturer')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="!scope.row.cloturee"
              :disabled="scope.row.id !== list[0].id"
              @change="setCampagneagricoleCloture(scope.row.id)"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="activeList==='corbeille' && checkRole(['admin'])" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setCampagneagricoleStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['modifier campagneagricole','visible campagneagricole','supprimer campagneagricole'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button v-if="scope.row.is_visible && checkPermission(['modifier campagneagricole'])" :disabled="scope.row.id !== list[0].id" type="primary" size="small" icon="el-icon-edit" @click="handleEditCampagneagricole(scope.row.id);" />
              <el-button v-if="checkPermission(['visible campagneagricole','supprimer campagneagricole'])" :disabled="scope.row.id !== list[0].id" type="danger" size="small" icon="el-icon-delete" @click="handleDeleteCampagneagricole(scope.row.id, scope.row.is_visible, scope.row.nom);" />
              <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsModal(scope.row)" />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCampagneagricolesList" /-->

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('campagneagricole.edit') + ' ' + currentCampagneagricole.nom : $t('campagneagricole.AddCampagneagricole')"
        :visible.sync="dialogCreateCampagneagricole"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="campagneagricoleCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="campagneagricoleForm" :rules="rules" :model="currentCampagneagricole" label-position="left">
            <el-form-item :label="$t('campagneagricole.nom')" prop="nom">
              <el-input v-model="currentCampagneagricole.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentCampagneagricole.description" type="textarea" />
            </el-form-item>
            <el-form-item
              :label="$t('campagneagricole.prevision')"
              prop="prevision"
              :rules="[
                { required: true, message: $t('campagneagricole.previsionFieldRequiredWarningEmpty')},
                { type: 'number', message: $t('campagneagricole.previsionFieldRequiredWarningType')}
              ]"
            >
              <el-input v-model.number="currentCampagneagricole.prevision" type="age" max="99999999999" autocomplete="off" />
            </el-form-item>
            <el-form-item :label="$t('campagneagricole.periode')" prop="periode">
              <el-col :span="8">
                <el-input-number v-model.number="currentCampagneagricole.dated" type="number" :min="2000" :max="9999" maxlength="4" pattern="\d{4}" :placeholder="$t('campagneagricole.yearBeginPlaceholder')" style="width: 100%;" required />
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="8">
                <el-input-number :value="datef" type="number" :min="2000" :max="9999" maxlength="4" pattern="\d{4}" :placeholder="$t('campagneagricole.yearEndPlaceholder')" :disabled="true" style="width: 100%;" required />
              </el-col>
              <!-- <p>{{periode}}</p> -->
            </el-form-item>
            <!--<el-upload
                class="upload-demo"
                action="http://sciagri.test/api"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="onChange"
                :limit="3"
                :multiple="false"
                :file-list="fileList"
                :auto-upload="false"
                list-type="picture"
              >
                <el-button size="small" type="primary">{{ $t('general.ImapeUploadFieldText') }}</el-button>
                <div slot="tip" class="el-upload__tip">{{ $t('campagneagricole.FileCaracteristicsText') }}</div>
              </el-upload>-->
            <el-form-item prop="lien" style="margin-bottom: 30px;">
              <Upload
                v-model="currentCampagneagricole.avatar"
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateCampagneagricole=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="campagneagricoleCreating" @click="editing ? updateCampagneagricole() : createCampagneagricole()">
              {{ campagneagricoleCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="detailDataM !== null? 'Détails de la campagne : '+ detailDataM.nom: 'Détails'"
        :visible.sync="detailM"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="campagneagricoleCreating"
        width="30%"
      >
        <div v-if="detailDataM !== null" class="content-inputs">
          <el-form ref="campagneagricoleForm" :rules="rules" :model="detailDataM" label-position="left">
            <el-form-item :label="$t('campagneagricole.nom')" prop="nom">
              <el-input v-model="detailDataM.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="detailDataM.description" type="textarea" />
            </el-form-item>
            <el-form-item
              :label="$t('campagneagricole.prevision')"
              prop="prevision"
              :rules="[
                { required: true, message: $t('campagneagricole.previsionFieldRequiredWarningEmpty')},
                { type: 'number', message: $t('campagneagricole.previsionFieldRequiredWarningType')}
              ]"
            >
              <el-input v-model.number="detailDataM.prevision" type="age" max="99999999999" autocomplete="off" />
            </el-form-item>
            <el-form-item :label="$t('campagneagricole.periode')" prop="periode">
              <el-col :span="8">
                <el-input-number v-model.number="detailDataM.dated" type="number" :min="2000" :max="9999" maxlength="4" pattern="\d{4}" :placeholder="$t('campagneagricole.yearBeginPlaceholder')" style="width: 100%;" required />
              </el-col>
              <el-col class="line" :span="1">-</el-col>
              <el-col :span="8">
                <el-input-number :value="datef" type="number" :min="2000" :max="9999" maxlength="4" pattern="\d{4}" :placeholder="$t('campagneagricole.yearEndPlaceholder')" :disabled="true" style="width: 100%;" required />
              </el-col>
              <!-- <p>{{periode}}</p> -->
            </el-form-item>
            <!-- <el-form-item :label="$t('campagneagricole.CampaignBannerFieldText')" prop="lien">
              <el-upload
                class="upload-demo"
                action="http://sciagri.test/api"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="onChange"
                :limit="3"
                multiple="true"
                :file-list="fileList"
                list-type="picture"
              >
                <el-button size="small" type="primary">{{ $t('general.ImapeUploadFieldText') }}</el-button>
                <div slot="tip" class="el-upload__tip">{{ $t('campagneagricole.FileCaracteristicsText') }}</div>
              </el-upload>
            </el-form-item> -->
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="detailM=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import Upload from '@/components/Upload/SingleImage';
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const campagneagricoleResource = new Resource('campagne_agricoles');
export default {
  name: 'CampagneagricolesList',
  components: { Upload, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      detailM: false,
      detailDataM: null,
      campagneagricoleCreating: false,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      // thumbPostUrl: this.$appbaseUrl + 'upload.php',
      currentCampagneagricole: {},
      dialogCreateCampagneagricole: false,
      currentCampagneagricoleId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('campagneagricole.NomRequired'), trigger: 'blur' }],
        description: [{ required: true, message: this.$t('campagneagricole.DescriptionRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {
    getLatestId(){
      return this.list[0].id;
    },
    datef(){
      return this.currentCampagneagricole.dated ? this.currentCampagneagricole.dated + 1 : 2000;
    },
  },
  created() {
    this.resetNewCampagneagricole();
    this.getCampagneagricolesList();
  },
  methods: {
    converted(data){
      this.currentCampagneagricole.avatar = data;
    },
    getBase64Image(src, callback, outputFormat) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      console.log('image crossOrigin', img.crossOrigin);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL = '';
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelCampagneagricoleForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterCampagneagricoles();
    },
    showDetailsModal(row){
      this.detailDataM = row;
      this.detailM = true;
    },
    async getCampagneagricolesList() {
      this.loading = true;
      const { data } = await campagneagricoleResource.list(this.query);
      this.list = data.reverse();
      // console.log('===== EDI');
      // console.log(data);
      // console.log('===== EVO');
      // const { limit, page } = this.query;
      // const { data, meta } = await campagneagricoleResource.list(this.query);
      /* this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      }); */
      // this.total = meta.total;
      this.loading = false;
    },
    async getSubCampagneagricolesList(subUrl) {
      this.loading = true;
      const { data } = await campagneagricoleResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterCampagneagricoles() {
      this.query.page = 1;
      this.getCampagneagricolesList();
    },
    handleCreateCampagneagricole() {
      this.editing = false;
      this.resetNewCampagneagricole();
      this.dialogCreateCampagneagricole = true;
      // this.getBase64Image(this.currentCampagneagricole.avatar, this.converted, 'jpg');
      this.$nextTick(() => {
        this.$refs['campagneagricoleForm'].clearValidate();
      });
    },
    handleEditCampagneagricole(id){
      this.editing = true;
      this.resetNewCampagneagricole();
      this.currentCampagneagricole = this.list.find(abonne => abonne.id === id);
      if (this.currentCampagneagricole.avatar && this.currentCampagneagricole.avatar.slice(0, 4) === 'data'){
        this.getBase64Image(this.currentCampagneagricole.avatar, this.converted, 'jpg');
      }
      console.log('campagne agricole recupéré ', this.currentCampagneagricole);
      const myArr = this.currentCampagneagricole.periode.split('-');
      this.currentCampagneagricole.dated = parseInt(myArr[0]);
      this.currentCampagneagricole.datef = parseInt(myArr[1]);
      this.fileList.push({ name: this.currentCampagneagricole.avatar, url: this.currentCampagneagricole.avatar });
      this.dialogCreateCampagneagricole = true;
      this.$nextTick(() => {
        this.$refs['campagneagricoleForm'].clearValidate();
      });
    },
    async setCampagneagricoleStatus(id, type){
      const data = await campagneagricoleResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async setCampagneagricoleCloture(id){
      this.$alert(`<strong>Le processus de clôture le la campagne est en cours...
        Cela prendra quelques minutes. Veillez patienter un moment avant de réactualiser pour vérifier si elle est effective</strong>`, 'Info', {
        dangerouslyUseHTMLString: true,
      });
      const data = await campagneagricoleResource.get('toogle_cloturee/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
        this.loading = false;
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
        this.loading = false;
      }
    },
    handleDeleteCampagneagricole(id, visible, name) {
      this.$confirm(this.$t('campagneagricole.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer campagneagricole']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              campagneagricoleResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('campagneagricole.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setCampagneagricoleStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    async createCampagneagricole() {
      this.$refs['campagneagricoleForm'].validate((valid) => {
        // var periodeValid = this.currentCampagneagricole.dated <= this.currentCampagneagricole.datef;
        try {
          this.currentCampagneagricole.roles = [this.currentCampagneagricole.role];
          this.currentCampagneagricole.periode = `${this.currentCampagneagricole.dated}-${this.currentCampagneagricole.dated + 1}`;
          // this.getBase64Image(this.currentCampagneagricole.avatar, this.converted, 'jpg');
          // console.log('here is the image', this.fileList, this.fileList[0]);
          // var blob = new Blob([this.fileList[0].url], { type: 'image/png' });
          // var reader = new FileReader();
          // reader.readAsDataURL(blob);
          // reader.onload = function() {
          //   return reader.result;
          // };
          // var mySrc;
          // var reader = new FileReader();
          // reader.readAsDataURL(blob);
          // reader.onloadend = function() {
          //   mySrc = reader.result;
          // };
          // var im;
          // var resu;
          // resu = this.blobToBase64(blob);
          // resu.then(res => {});
          // this.currentCampagneagricole.lien = res;
          // this.currentCampagneagricole.avatar = res;
          this.campagneagricoleCreating = true;
          console.log('campagne agricole to be send', this.currentCampagneagricole);
          campagneagricoleResource
            .store(this.currentCampagneagricole)
            .then(async(response) => {
              this.$message({
                message: this.$t('campagneagricole.NewCampagneagricole') + ' ' + this.currentCampagneagricole.nom + ' ' + this.$t('campagneagricole.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCampagneagricoleId = response.data.id;
              this.resetNewCampagneagricole();
              this.dialogCreateCampagneagricole = false;
              this.handleFilterCampagneagricoles();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.campagneagricoleCreating = false;
            });
          // im = res; // res is base64 now
          // console.log('LE VOICICICICI : ', im);

          // var mySrc = reader.onload;
          // this.currentCampagneagricole.lien = blob;
          console.log('ENOUA');
          // console.log(mySrc);
          console.log('ENOUA');
        } catch (e) {
          // if (!periodeValid) {
          //   this.$message({
          //     // message: this.$t('campagneagricole.NewCampagneagricole') + ' ' + this.currentCampagneagricole.nom + ' ' + this.$t('campagneagricole.iscreatedSuccessfully'),
          //     message: 'Veuillez vérifier la date de début et de fin de la période !',
          //     type: 'warning',
          //   });
          // }
          // if (this.isEmptyArray(this.fileList)) {
          //   this.$message({
          //     // message: this.$t('campagneagricole.NewCampagneagricole') + ' ' + this.currentCampagneagricole.nom + ' ' + this.$t('campagneagricole.iscreatedSuccessfully'),
          //     message: 'Veuillez sélectionner une image pour la campagne !',
          //     type: 'warning',
          //   });
          // }
          console.log('for error', e);
          return false;
        }
      });
    },
    blobToBase64(blobD) {
      const reader = new FileReader();
      reader.readAsDataURL(blobD);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    updateCampagneagricole() {
      this.$refs['campagneagricoleForm'].validate((valid) => {
        if (valid) {
          if (this.currentCampagneagricole.avatar.slice(0, 4) === 'data'){
            this.getBase64Image(this.currentCampagneagricole.avatar, this.converted, 'jpg');
          } else {
            delete this.currentCampagneagricole.avatar;
          }
          console.log('campagne agricole that will be send', this.currentCampagneagricole);
          this.campagneagricoleCreating = true;
          campagneagricoleResource.update(this.currentCampagneagricole.id, this.currentCampagneagricole).then(response => {
            this.resetNewCampagneagricole();
            this.handleFilterCampagneagricoles();
            this.$message({
              message: this.$t('campagneagricole.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateCampagneagricole = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.campagneagricoleCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewCampagneagricole() {
      this.currentCampagneagricole = {
        nom: '',
        description: '',
        periode: '',
        dated: '',
        datef: '',
      };
      this.fileList = [];
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('campagneagricole.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des campagnes SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      this.fileList = fileList;
      console.log(this.fileList.length);
      if (this.fileList.length === 2) {
        this.fileList.splice(0, 1);
      }
      // this.fileList.push({ name: file.name, url: file.url, status: file.status });
      console.log(this.fileList);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
